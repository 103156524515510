import './style.css';

const Badrequest = () => {
    return (
        <div className="contener-404">
            <h1>404</h1>
            <h2>We can't find that page</h2>
        </div>
    )
};

export default Badrequest;